<template>
    <div class="view-app">
        <sidebar-view>
            <dashboard-container>
                <div class="card mb-7">
                    <div
                        class="my-8 d-flex justify-content-center"
                        v-if="loading"
                    >
                    
                        <loading :size="55" color="primary" />
                    </div>
                    <div v-else class="card-body p-5">
                        <help ref="supportModal" />
                        <div class="d-flex justify-content-end mt-3">
                            <btn @click="sendSupportRequest()" app :loading="sendLoading">
                                Send
                                <i class="fas fa-paper-plane pl-1"></i>
                            </btn>
                        </div>
                    </div>
                </div>
            </dashboard-container>
        </sidebar-view>
    </div>
</template>

<script>
import sidebarView from './sidebar/sidebar';
import dashboardContainer from './dashboard_container';
import help from '@/views/help';

export default {
    data() {
        return {
            supportSentSnackbar: false,
            loading: true,
            sendLoading: false,
        };
    },
    components: {
        help,
        sidebarView,
        dashboardContainer,
    },
    mounted() {
        this.$nextTick().then(() => {
            this.loading = false;
        });
    },
    methods: {
        async sendSupportRequest() {
            this.sendLoading = true;
            if (!(await this.$refs.supportModal.sendRequest())) {
                this.sendLoading = false;
                return;
            }
            this.sendLoading = false;
        },
    },
};
</script>
